.exportdialog .el-dialog__title {
  font-size: 16px;
}
.exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog .export-box > div {
  padding-left: 30px;
}
.exportdialog .export-box .el-button.is-disabled,
.exportdialog .export-box .el-button.is-disabled:focus,
.exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
