























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.dialogBox {
  height: 600px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0;
    h3 {
      padding-bottom: 0.75rem;
    }
  }
  .divBox2 {
    width: 100%;
  }
}
tr {
  height: 32px;
  td {
    max-width: 7rem;
    text-align: center;
  }
}
.tableFooter {
  display: flex;
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  > div {
    width: 50%;
    display: flex;
    > div {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 50%;
      span {
        cursor: pointer;
      }
    }
  }
}
.tableFooter3 {
  display: flex;
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  > div {
    width: 30%;
    display: flex;
    > div {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 70%;
      span {
        cursor: pointer;
      }
    }
  }
}
.popUp {
  .el-dialog__header {
    overflow: hidden;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.btn {
  display: flex;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
